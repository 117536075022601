import ReactGA from 'react-ga4';

type AnalyticEvent =
  | 'LOGIN_SUCCESS'
  | 'LOGIN_FAILED'
  | 'LOGOUT'
  | 'NAVIGATE_SEARCH_FOOD'
  | 'SEARCHING_FOOD_TERM'
  | 'CHOOSING_MERCHANT'
  | 'CHOOSING_MERCHANT_FOOD'
  | 'ADD_TO_CART'
  | 'ADD_MORE_CART_ITEM'
  | 'EDIT_CART_ITEM'
  | 'REMOVE_FROM_CART'
  | 'VIEW_CART'
  | 'USER_UPDATE_COORDINATE'
  | 'CHECKOUT'
  | 'SEARCH_MERCHANT_MENU'
  | 'CLICK_ON_SUPERMARKET'
  | 'CLICK_ON_BELANJA_AJA'
  | 'CLICK_ON_KIRIM_AJA'
  | 'CLICK_ON_MARKET_AJA'
  | 'CLICK_ON_LAUNDRY_AJA';

type MarketAjaEvent = 'CHOOSING_STORE' | 'CHOOSING_STORE_PRODUCT';

export const track = (event: AnalyticEvent | MarketAjaEvent, data?: Record<string, string | number | boolean>) => {
  if (import.meta.env.MODE === 'production') {
    ReactGA.event(event, data);
  }
};

export const tagUser = (user_id: string) => {
  if (import.meta.env.MODE === 'production') {
    ReactGA.gtag('config', 'G-PGVFWBCSG8', {
      user_id,
    });
  }
};
