import { Layout } from 'antd';
import { ReactNode } from 'react';

interface AppLayoutProps {
  children: ReactNode;
  showHeader?: boolean;
  headerComponent?: {
    accessoryLeft?: ReactNode;
    accessoryRight?: ReactNode;
  };
}

export default function BlankLayout(props: AppLayoutProps) {
  const { children } = props;

  return (
    <Layout className={'min-h-screen'} style={{ background: '#F7F7F7' }}>
      <Layout.Content className={'h-full overflow-auto'}>{children}</Layout.Content>
    </Layout>
  );
}
